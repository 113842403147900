<template>
  <b-custom-tabs id="WebCallRequestTabs" :tabs="tabs" lazy />
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        { title: "Müşteri Bilgileri", component: () => import("./WebCallRequestInfo.vue") },
        { title: "Notlar", component: () => import("./notes/CallRequestNotesList.vue") },
      ],
    };
  },
};
</script>

<style></style>
